<template>
  <div id="class-group" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <div class="columns">
        <div class="column is-6 is-offset-3">
          <ValidationObserver ref="observer" v-slot="{ passes }">
            <form @submit.prevent="passes(add)">
              <SdInput
                type="text"
                rules="required"
                label="Name"
                v-model="classGroup.name"
                placeholder="Enter name"
              />
              <SdInput
                type="text"
                rules="required"
                label="Hierarchy"
                v-model="classGroup.hierarchy"
                placeholder="Enter a value"
              />
              <SdInput
                type="text"
                rules="required"
                label="Passing score"
                v-model="classGroup.passingScore"
                placeholder="Passing Score"
              />
              <SdSelect
                rules="required"
                label="Category"
                v-model="classGroup.category"
              >
                <option v-for="category in categories" :key="category">
                  {{ category }}
                </option>
              </SdSelect>
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right"
              >
                Submit
              </button>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import GRADIING_SCALES from '../../../graphql/grading_scales/GradingScales.gql'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  name: 'class-group',
  data() {
    return {
      schoolId: null,
      pageTitle: 'Class Group',
      gradingScales: [],
      skipQuery: true,
      classGroup: {
        id: null,
        schoolId: null,
        name: null,
        hierarchy: 0,
        category: null,
        passingScore: null,
      },
      categories: [],
    }
  },
  apollo: {
    classGroup: {
      query: gql`
        query ClassGroupId($id: ID!) {
          classGroup(id: $id) {
            id
            schoolId
            name
            hierarchy
            category
            passingScore
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
    gradingScales: {
      query: GRADIING_SCALES,
      variables() {
        return { schoolId: this.schoolId }
      },
    },
  },
  watch: {
    gradingScales(data) {
      this.categories = new Set(data.map((item) => item.category))
    },
  },
  methods: {
    add() {
      const name = this.classGroup.name
      const hierarchy = parseInt(this.classGroup.hierarchy)
      const category = this.classGroup.category
      const passingScore = parseInt(this.classGroup.passingScore)
      if (this.$route.params.id === 'new') {
        const arr = this.$store.getters.getRecords.map((v) =>
          v.name.toLowerCase()
        )
        if (arr.includes(this.classGroup.name.trim().toLowerCase())) {
          this.$buefy.notification.open({
            duration: 5000,
            message: `${this.classGroup.name}: Duplicates not allowed`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
          })
          return
        }
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createClassGroupQuery(
                $school_id: Int!
                $name: String!
                $hierarchy: Int!
                $category: String!
                $passingScore: Int!
              ) {
                createClassGroup(
                  input: {
                    schoolId: $school_id
                    name: $name
                    hierarchy: $hierarchy
                    category: $category
                    passingScore: $passingScore
                  }
                ) {
                  classGroup {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              school_id: parseInt(this.schoolId),
              name: name,
              hierarchy: hierarchy,
              category: category,
              passingScore: passingScore,
            },
            errors(error) {
              this.$buefy.notification.open({
                duration: 5000,
                message: error.messsage,
                position: 'is-top',
                type: 'is-danger',
                hasIcon: true,
              })
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createClassGroup.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/class_groups`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateClassGroupQuery(
                $id: Int!
                $name: String!
                $hierarchy: Int!
                $category: String!
                $passingScore: Int!
              ) {
                updateClassGroup(
                  input: {
                    id: $id
                    name: $name
                    hierarchy: $hierarchy
                    category: $category
                    passingScore: $passingScore
                  }
                ) {
                  classGroup {
                    id
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.classGroup.id),
              name: name,
              hierarchy: hierarchy,
              category: category,
              passingScore: passingScore,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateClassGroup.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/class_groups`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Class Groups',
        route: `/school/${this.$route.params.school_id}/class_groups`,
      },
    ])
  },
}
</script>
